import breadcrumbComp from "../../common/breadcrumb-comp.vue";
import Utility from "../../../shared/utility";
import EncryptUtility from "../../utility/js/encrypt-utility";
import AddFavourite from "../../utility/js/addFav";
import BackToTop from "../../common/back-to-top.vue";
import exportLimit from "../../common/export-limit.vue";
import reportService from "./reports-service";
export default {
  name: "partsDueReport",
  data() {
    return {
      userId: EncryptUtility.localStorageDecrypt("userID"),
      actionList: [],
      favouriteList: [],
      specificSelected: "",
      showData: false,
      exportDialog: false,
      hideSpecific: false,
      showTable: false,
      disableDate: false,
      showPart: false,
      partNum: "",
      partDesc: "",
      mainPage: "",
      excelName: "",
      page: 1,
      displayCost: "",
      totalPages: 0,
      subPage: "",
      restForm: false,
      backSlash: true,
      pageUrl: "",
      showAdd: false,
      refresh: true,
      sDate: "",
      eDate: "",
      selectedProject: "",
      selectedBusiness: "",
      selectedStatus: "",
      selectedCountry: "",
      selectedOrderType: "",
      selectedSODetails: "",
      partSearchKey: "",
      partResultList: [],
      showPartNumDetails: false,
      businessList: [],
      detailsData: [],
      statusList: [],
      countryList: [],
      uniqueCountryList: [],
      uniqueOEM: [],
      orderTypeList: [],
      soList: [],
      uniqueSODetails: [],
      reportData: [],
      startDate: false,
      showStatus: false,
      showCountry: false,
      showOrderType: false,
      soType: false,
      showSearch: false,
      timeoutId: null,
      endDate: false,
      showAllForm: false,
      maxStartDate: new Date().toISOString().split("T")[0],
      minEndDate: new Date().toISOString().split("T")[0],
      maxEndDate: new Date(new Date().getTime()).toISOString().split("T")[0],
      projectList: [],
      debouncedGetPartList:[],
      totalRecords: 0,
      filterObj: {
        pass:0,
        user_id:this.userId,
        proj_id:0,
        part_num:"",
        bu_id:0,
        status:0,
        country: "",
        sotype_id:0,
        order_type: "",
        PageNumber:1,
        export:0,
        guid:""
      },
      headerReport: [
        { text: "Country", align: "start", value: "COUNTRY", class: "primary customwhite--text" },
        { text: "SO Number", value: "SALES ORDER", class: "primary customwhite--text" },
        { text: "Line No", value: "LINE NO", class: "primary customwhite--text" },
        { text: "Cust Order No", value: "CUST ORDER NO", class: "primary customwhite--text" },
        { text: "RMA", value: "RMA", class: "primary customwhite--text" },
        { text: "PO Number", value: "PO_NUM", class: "primary customwhite--text" },
        { text: "Request Part No", value: "REQ_PART", class: "primary customwhite--text" },
        { text: "Ship Class", value: "SHP_CLASS", class: "primary customwhite--text" },
        { text: "Ship Part Number", value: "SHP_PART", class: "primary customwhite--text" },
        { text: "OEM", value: "OEM", class: "primary customwhite--text" },
        { text: "Qty", value: "QTY", class: "primary customwhite--text" },
        { text: "Alloc", value: "ALLOC", class: "primary customwhite--text" },
        { text: "BO", value: "BO", class: "primary customwhite--text" },
        { text: "Available", value: "AVAILABLE", class: "primary customwhite--text" },
        { text: "Ware", value: "WARE", class: "primary customwhite--text" },
        { text: "Carrier", value: "CARRIER", class: "primary customwhite--text" },
        { text: "Order Type", value: "ORD_TYPE", class: "primary customwhite--text" },
        { text: "Status", value: "STATUS", class: "primary customwhite--text" },
        { text: "So Type", value: "SO_TYPE", class: "primary customwhite--text" },
        { text: "AGE", value: "AGE", class: "primary customwhite--text" },
        { text: "Req Date", value: "REQDATE", class: "primary customwhite--text" },
        { text: "Auth Date", value: "AUTHDATE", class: "primary customwhite--text" },
        { text: "Allocated", value: "ALLOCATED", class: "primary customwhite--text" },
        { text: "Picked Date", value: "PickDate", class: "primary customwhite--text" },
        { text: "BCN", value: "BCN", class: "primary customwhite--text" },
        { text: "SerialNo", value: "SerialNo", class: "primary customwhite--text" },

      ],
    };
  },
  async created() {
    this.route = this.$route.path.replace("/", "");
    let data = await AddFavourite.getPageAction(this.userId, this.route);
    this.actionList = data.actionList;
    this.mainPage = data.mainPage;
    this.favouriteList = data.favouriteList;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
    this.filterObj.user_id = this.userId;
    this.getProjectData()
    this.debouncedGetPartList  = Utility.debounce(this.getPartList, 500);
  },
  mounted() { },
  computed:{
    sortedBusinessOptions() {
      return Utility.getAllAtTop(this.businessList, 'business');
    },
    sortedSOTypeOptions() {
      return Utility.getAllAtTop(this.soList, 'so_desc');
    },
   
  },
 
  methods: {

    //To add and remove From Fav
    addFav() {
      this.showAdd = !this.showAdd;
      AddFavourite.add_remove_Favourite(this.showAdd, this.userId, this.route);
    },
    //To brings page based actions to the page
    actionSetter(action) {
      if (this.actionList !== undefined && this.actionList !== null && this.actionList !== "") {
        let filter = this.actionList.filter((entry) => entry.SystemName === action);
        return filter !== undefined && filter !== null && filter.length == 1 ? true : false;
      } else return false;
    },
    async exportData() {
      this.export50kData = true;
      this.exportDialog = false;
    },
    async getProjectData(){
      let projectData = await reportService.getOpenSODetailsData("post", this.filterObj, false);
      this.projectList = projectData;
    },

    // to export the Execel file
    async exportExcel() {
      if (this.totalRecords <= 15000) {
        this.filterObj.export = 1;
        this.filterObj.guid=Math.floor(Math.random()*100000);
        this.excelName =
          "Open_Details_Report" + new Date().toISOString().replace("T", "_").replace("Z", "").replace(".", "").replaceAll("-", "_") + ".xls";
        const response = await this.axios.post("/rt/so_open_orders_report", this.filterObj);
        let responseData = JSON.parse(response.data.body.message);
        responseData?.forEach((element) => {
          element.REQDATE = Utility.convertESTToLocalOnlyDate(element.REQDATE);
          element.AUTHDATE = Utility.convertESTToLocal(element.AUTHDATE);
        });
        this.export50kData = false;
        return responseData;
      } else {
        this.exportDialog = true;
        this.finishDownload();
        return true;
      }
    },
    //Start the Loader when excel process starts
    startDownload() {
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
    },
    // end the Loader when the process is completed
    finishDownload() {
      let LoaderDialog = {
        visible: false,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
    },
    //on Change Project
    onChangeProject() {
      this.filterObj.proj_id = this.selectedProject;
      this.filterObj.pass = 1;
      this.showAllForm = false;
      this.showGo = false;
      this.showPart = true;
      this.disableDate = true;
      this.getBusinessData();
    },
    async getBusinessData() {
      let businessData = await reportService.getOpenSODetailsData("post", this.filterObj, false);
      
      businessData=[...new Map(businessData.map((item) => [item["business"], item])).values()];
      this.businessList= businessData.map((x) => ({
        business: x.business,
        bu_key: x.bu_key,
      }));
    },
   

    async onChangeBusiness() {
      this.hideSpecific = true;
      this.filterObj.pass = 2;
      this.filterObj.bu_id = this.selectedBusiness;
      this.detailsData = await reportService.getOpenSODetailsData("post", this.filterObj, false);
      const unquieStatus = [...new Map(this.detailsData.map((item) => [item["status"], item])).values()];
      this.statusList = unquieStatus.map((x) => ({
        status_desc: x.status_desc,
        status: x.status,
      }));
      this.showStatus = true;
      this.showCountry = false;
      this.showOrderType = false;
      this.soType = false;
      this.displayCost = false;
      this.showSearch = false;
      this.orderTypeList = [];
      this.countryList = [];
      this.orderTypeList = [];
      this.soList = [];
      this.showCost = false;
      this.selectedStatus = "";
      this.selectedBusinessOem = "";
      this.selectedSODetails = "";
    },
    onChangeStatus(status_id) {
      this.filterObj.status = status_id
      this.uniqueCountryList = this.detailsData.filter((x) => x.status == status_id);
      const uniqueCountry = [...new Map(this.uniqueCountryList.map((item) => [item["sh_country"], item])).values()];
      this.countryList = uniqueCountry.map((x) => ({
        sh_country: x.sh_country,
      }));
      this.showCountry = true;
      this.showData = false;
      this.showOrderType = false;
      this.soType = false;
      this.displayCost = false;
      this.showSearch = false;
      this.oemList = [];
      this.soList = [];
      this.showCost = false;
      this.selectedSODetails = "";
      this.selectedCountry = "";
      this.selectedOrderType = "";
    },
    onChangeCountry(Country_id) {
      this.filterObj.country = Country_id
      this.uniqueSODetails = this.uniqueCountryList.filter((x) => x.sh_country == Country_id);
      const uniqueSoDesc = [...new Map(this.uniqueSODetails.map((item) => [item["so_desc"], item])).values()];
      this.soList = uniqueSoDesc.map((x) => ({
        so_desc: x.so_desc,
        so_type_id: x.sotype_key,
      }));
      this.soType = true;
      this.displayCost = true;
      this.showSearch = false;
      this.showData = false;
      this.showCost = false;
      this.selectedSODetails = "";
    },
    onChangeSOType(so_id) {
      this.filterObj.sotype_id = so_id
      this.unqiueOrderType = this.uniqueCountryList.filter((x) => x.sotype_key == so_id);
      const unqiueOrderType = [...new Map(this.uniqueSODetails.map((item) => [item["order_type"], item])).values()];
      this.orderTypeList = unqiueOrderType.map((x) => ({
        order_type: x.order_type,
      }));
      this.showOrderType=true
      this.showSearch = true;
      this.showData = false;
    },

    async onClickSearch(PageNumber) {
      this.filterObj.pass = 3;
      this.filterObj.PageNumber = PageNumber;
      this.filterObj.bu_id = this.selectedBusiness;
      this.filterObj.country = this.selectedCountry;
      this.filterObj.status = this.selectedStatus;
      this.filterObj.order_type = this.selectedOrderType;
      this.filterObj.sotype_id = this.selectedSODetails;
      this.filterObj.part_num = this.partSearchKey;
      this.filterObj.guid=Math.floor(Math.random()*100000);
      let tableData = await reportService.getOpenSODetailsData("post", this.filterObj, false);
      this.reportData = tableData?.Result;
      if (this.reportData?.length > 0) {
        this.showData = true;
        this.reportData?.forEach((element) => {
          if(element.REQDATE)
          element.REQDATE = Utility.convertESTToLocalOnlyDate(element.REQDATE);
          element.AUTHDATE = Utility.convertESTToLocal(element.AUTHDATE);
          if(element.PickDate)
            element.PickDate = Utility.convertESTToLocal(element?.PickDate);
          if(element.ALLOCATED)
            element.ALLOCATED = Utility.convertESTToLocal(element?.ALLOCATED);
        });
      } else {
        this.reportData = [];
        let Alert = {
          type: "error",
          isShow: true,
          message: "No Record Found",
        };
        this.$store.commit("ConfigModule/Alert", Alert);
      }
      this.$vuetify.goTo(0);
      this.showTable = true;
      this.totalRecords = tableData?.TotalRecords;
      this.totalPages = tableData?.TotalPages;
    },
    resetFunction() {
      this.selectedProject = "";
      this.disableDate = false;
      (this.partNum = ""), (this.showData = false);
      this.showGo = true;
      (this.partDesc = ""), (this.selectedBusiness = "");
      this.partSearchKey = "";
      this.restForm = false;
      this.showPart = false;
      this.showStatus = false;
      this.specificSelected = false;
      this.showCountry = false;
      this.showOrderType = false;
      this.soType = false;
      this.displayCost = false;
      this.showAllForm = false;
      this.showSearch = false;
      this.businessList = [];
      this.projectList = [];
      this.statusList = [];
      this.orderTypeList = [];
      this.countryList = [];
      this.orderTypeList = [];
      this.soList = [];
      this.showTable = false;
      this.showCost = false;
      this.selectedStatus = "";
      this.selectedBusinessOem = "";
      this.selectedSODetails = "";
      this.hideSpecific = false;
      this.reportData = [];
      this.totalRecords = 0;
      this.timeoutId=null
      this.totalPages = 1;
      this.filterObj={
        user_id:this.userId,
         proj_id:0,
        part_num:"",
        bu_id:0,
        status:0,
        country: "",
        sotype_id:0,
        order_type: "",
        PageNumber:1,
        export:0,
        guid:""
      
      }
      this.getProjectData()
    },
    onClickSpecificPartNumber() {
      this.specificSelected = true;
    },
    async searchPartNum() {
      this.partSearchKey = this.partSearchKey?.toUpperCase();
      if(this.partSearchKey?.length>2){
        this.debouncedGetPartList();
    }
 
    },

    async getPartList(){
      this.showPartNumDetails = true;
      let SearchObj = {
        UserId: this.userId,
        SearchStr: this.partSearchKey,
        proj_key: this.selectedProject,
      };
      
      let partResultData = await reportService.showPartNumDetailsData("post", SearchObj, false);
      this.partResultList = partResultData?.Resultset;
    },

    async showPartDesc(item) {
      this.showPartDetails = true;
      this.partNum = item.PartNo;
      this.partDesc = item.Description;
      this.partSearchKey = item.PartNo;
      this.showPartNumDetails = false;
      this.filterObj.part_num = this.partNum;
      let dataExists = await reportService.getOpenSODetailsData("post", this.filterObj, false);
      if (dataExists?.message=="NA" ||!dataExists) {
        this.showAllForm = false;
        let Alert = {
          type: "error",
          isShow: true,
          message: "No Record Found",
        };
        this.$store.commit("ConfigModule/Alert", Alert);
      }
      
      else {
        this.showAllForm = true;
        
      }
    },

    clearPartShip() {
      this.showPartDetails = false;
      this.partSearchKey = "";
      this.partResultList = [];
      this.partNum = "";
      this.partDesc = "";
    },
  },
  components: {
    breadcrumbComp,
    BackToTop,
    exportLimit,
  },
};
